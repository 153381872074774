import React from 'react'
import "./FirstStep.scss"
import PersonnelSearch from './PersonnelSearch'
//import { useTranslation } from 'react-i18next'
import { Spinner } from '@cd/sdds-common-components-react'
import { t } from 'i18next'

type FirstStepProps = {
    storeData: SearchState,
    personnelSearch: any,
    selectingPersonnel(personnel: any): void,
    selectedPersonnel: any
    requirePersonelSearch: boolean
    hasError: boolean
}
const FirstStep = (props: FirstStepProps) => {
    const { storeData, personnelSearch, selectingPersonnel, selectedPersonnel, requirePersonelSearch, hasError } = props

    return (<>
        {storeData &&
            <div className="st-first-step">
                {requirePersonelSearch && <div>
                    <div className="sdds-u-mt2"> 
                        {!personnelSearch && !hasError && <Spinner />}
                        {personnelSearch && personnelSearch.length > 0 && <>
                        <p className="title">{t("TS_core:PERSONNEL")}</p>
                        <PersonnelSearch hasError={hasError} personnelList={personnelSearch} onPersonSelected={selectingPersonnel} selectedPersonnel={selectedPersonnel} />
                        </>}
                    </div>
                </div>}
               {!requirePersonelSearch && <div className="sdds-body-02 no-input-msg">{t("TS_core:NoInputsRequired")}</div>}
                {/* <div className="other">
                    <div className="sdds-headline-05"> Other Parameters</div>
                    <div className="otherWrapper">
                        <div>
                            <p className="title sdds-detail-04">{t("Equipment")}</p>
                            <div className="equipment sdds-body-02">
                                <div>
                                    <p className="sdds-headline-06">Alias </p>
                                    <p className="sdds-headline-06">Chassis serial No.</p>
                                    <p className="sdds-headline-06">VIN</p>
                                </div>
                                <div>
                                    <p>{storeData.selectedVehicle?.alias}</p>
                                    <p>{storeData.selectedVehicle?.chassisNumber}</p>
                                    <p>{storeData.selectedVehicle?.identification}</p>
                                </div>
                            </div>
                        </div>
                        <div className="secondColumn">
                            <p className="title sdds-detail-04">COMMUNICATOR</p>
                            <div className="communicator">
                                <p>{storeData.selectedCommunicator?.rtcType}</p>
                            </div>
                        </div>
                        <div>
                            <p className="title sdds-detail-04">CUSTOMER</p>
                            <div className="customer">
                                <p>{storeData.selectedCustomer?.name}</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>}
    </>
    )
}

export default FirstStep;