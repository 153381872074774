import { createSlice } from '@reduxjs/toolkit'
import { getPersonalsList, getPackages, executeFunction, getFunctionsFromPackage, getFunctionLogs, deleteFunctionLogs } from '../../adapters/TsGuide';
import { groupBy, find, map, filter } from "lodash";
import toArray from "lodash/toArray";
import { utcTimeFromString } from '../../helpers/timeFormatter';
import { getEqParams } from '../../helpers/docsFunctionHelper';

const getInitialState = (packages: Array<PackageType> | null): TsGuideState => {
    const tsGuideInitialState: TsGuideState = {
        packages: packages,
        selectedPackage: null,
        step: 1,
        personnelsList: null,
        selectedPersonnel: null,
        functions: [],
        sessionLogs: [],
        sessionsInfo: {
            error: 0,
            warning: 0
        },
        step2TestLists: [],
        step3TestLists: [],
        areAnyTestsRunning: true,
        apiError: false,
        apiNoData: false
    };
    return tsGuideInitialState;
}

// Slice
const slice = createSlice({
    name: 'tsGuide',
    initialState: getInitialState(null),
    reducers: {
        updatePackagesList: (state, action) => {
            state.packages = action.payload
        },
        storeSelectedPackage: (state, action) => {
            const newState = getInitialState(state.packages);
            newState.selectedPackage = action.payload;
            return newState
        },
        getPersonelList: (state, action) => {
            state.personnelsList = action.payload
        },
        updateSelectedPersonnel: (state, action) => {
            state.selectedPersonnel = action.payload
        },
        updateStep: (state, action) => {
            state.step = action.payload
        },
        updateTestLists: (state, action) => {
            if (!action.payload || action.payload.length === 0) {
                state.apiNoData = true;
                state.apiError = false;
            } else {
                state.functions = map(action.payload, (check: PackageFunctionType) => {
                    check.runningStatus = "Default";
                    check.sessionId = "";
                    return check;
                });
                state.functions = action.payload; //keyBy(action.payload,"id");
                const groupedList = groupBy(action.payload, (value) => value.step);
                const [step2, step3] = toArray(groupedList);
                state.step2TestLists = step2
                state.step3TestLists = step3
                state.apiNoData = false;
                state.apiError = false;
            }
        },
        updateSessionLogs: (state, action) => {
            const { logs, functionId } = action.payload;

            if (logs && logs.length > 0) {

                var check = null;
                if (state.step === 2) {
                    check = find(state.step2TestLists, { id: functionId });
                } else {
                    check = find(state.step3TestLists, { id: functionId });
                }
                if (check) {
                    check.logs = logs;
                    check.faultCode = logs[logs.length - 1].faultCode;
                }
                if (state.step === 2) {
                    const warning = filter(state.step2TestLists, function (e) {
                        return (e.logs && e.logs.length > 0 && e.logs[e.logs.length - 1].status === 6)
                    });
                    const error = filter(state.step2TestLists, function (e) {
                        return (e.logs && e.logs.length > 0 && e.logs[e.logs.length - 1].status === 5)
                    });
                    state.sessionsInfo.error = error.length;
                    state.sessionsInfo.warning = warning.length;
                }
            }
        },
        updateApiError: (state, action) => {
            state.apiError = action.payload;
        },
        updateTestState: (state, action) => {
            const { id, sessionId } = action.payload;
            var testFunction = null
            if (state.step === 2) {
                testFunction = find(state.step2TestLists, { id });
            } else {
                testFunction = find(state.step3TestLists, { id });
            }
            if (testFunction) {
                testFunction.sessionId = sessionId
                testFunction.logs = []
            }

        },
        updateCheckRuningStatus: (state, action) => {
            const { status, functionId } = action.payload;
            let check = null;
            let stepFunctions: Array<PackageFunctionType> = [];

            if (state.step === 2) {
                stepFunctions = state.step2TestLists;
            } else {
                stepFunctions = state.step3TestLists;
            }
            check = find(stepFunctions, { id: functionId });
            if (check) {
                check.runningStatus = status
            }
            const areAnyTestStillRunning = filter(stepFunctions, { runningStatus: "Running" }) || [];
            state.areAnyTestsRunning = areAnyTestStillRunning.length > 0;
        },
        updateCheckRuningEndTime: (state, action) => {
            const { timestamp, functionId } = action.payload;
            var check = null;
            if (state.step === 2) {
                check = find(state.step2TestLists, { id: functionId });
            } else {
                check = find(state.step3TestLists, { id: functionId });
            }
            if (check) {
                check.executionEndTime = timestamp
            }
        },
    },
});
export default slice.reducer

// Actions
const { updatePackagesList, updateStep, getPersonelList, updateTestLists, updateCheckRuningEndTime,
    updateSelectedPersonnel, updateSessionLogs, updateApiError, storeSelectedPackage, updateTestState, updateCheckRuningStatus } = slice.actions

export const fetchPackagesList = () => async (dispatch: any) => {
    try {
        const res = await getPackages()
        dispatch(updatePackagesList(res.data));

    } catch (e) {
        console.error(e);
        dispatch(updateApiError(true));
    }
}
export const updateNextStep = (value: number) => async (dispatch: any) => {
    try {
        dispatch(updateStep(value));
    } catch (e) {
        console.error(e);
        return console.error(e);
    }
}
export const fetchPersonalTest = (value: string) => async (dispatch: any) => {
    try {
        const res = await getPersonalsList(value)
        dispatch(getPersonelList(res.data));

    } catch (e) {
        console.error(e);
        dispatch(updateApiError(true));
    }
}
export const selectingPersonnal = (value: string) => async (dispatch: any) => {
    try {
        dispatch(updateSelectedPersonnel(value));

    } catch (e) {
        return (console.error(e));
    }
}

export const fetchFunctionsByPackage = (packageId: string) => async (dispatch: any) => {
    try {
        const response = await getFunctionsFromPackage(packageId)
        dispatch(updateTestLists(response.data.functions));
    } catch (e) {
        console.error(e);
        dispatch(updateApiError(true));
    }
}


export const updateSelectedPackage = (selectedPackage: PackageType) => async (dispatch: any) => {
    try {
        dispatch(storeSelectedPackage(selectedPackage));
    } catch (e) {
        console.error(e);
        dispatch(updateApiError(true));
    }
}


export const reStartAllChecksExecution = (step: number, functionParameterValues: any) => async (dispatch: any, getState: any) => {
    try {
        let stepFunctions: PackageFunctionType[] = [];
        const state = getState();
        if (step === 2) {
            stepFunctions = state.tsGuide.step2TestLists as PackageFunctionType[];
        } else {
            stepFunctions = state.tsGuide.step3TestLists as PackageFunctionType[];
        }
        const areAnyTestStillRunning = find(stepFunctions, { runningStatus: "Running" });
        if (!areAnyTestStillRunning) {
            stepFunctions.forEach(async (docsFunction: PackageFunctionType) => {
                setTimeout(async () => {
                    const functionParams = getEqParams(docsFunction, functionParameterValues);
                    await dispatch(startExecution(docsFunction.id, functionParams));
                }, 1000);
            });
        }

    } catch (e) {
        console.error(e);
        dispatch(updateApiError(true));
    }
}

export const startExecution = (id: string, params: any) => async (dispatch: any) => {
    try {
        dispatch(updateCheckRuningStatus({ functionId: id, status: "Running" }));
        const response = await executeFunction(params)
        dispatch(updateTestState({ id, sessionId: response.data }));

        setTimeout(async () => {
            await dispatch(fetchAndStoreFunctionLogs(response.data as string, id));
        }, 3000);

    } catch (e) {
        console.error(e);
        dispatch(updateApiError(true));
    }
}

export const fetchAndStoreFunctionLogs = (sessionId: string, functionId: string) => async (dispatch: any) => {
    try {

        const response = await getFunctionLogs(sessionId);
        if (response && response.data && response.data.length > 0) { // if empty, fetch logs again using same session          
            const responseData = response.data as TestLogType[];
            const statusCode = responseData[responseData.length - 1]?.status;

            let runningStatus: TestResultTypes = 'Running';
            switch (statusCode) {
                case 1: runningStatus = 'Passed'; break;
                case 2: runningStatus = 'Failed'; break; //based on UI code in 1.0 status=2 is also a failure code
                case 5: runningStatus = 'Failed'; break;
                case 6: runningStatus = 'Warning'; break;
                default: runningStatus = 'Default'; break;
            }
            dispatch(updateSessionLogs({ logs: response.data, functionId }));
            if (statusCode === 0) {
                setTimeout(async () => {
                    await dispatch(fetchAndStoreFunctionLogs(sessionId, functionId));
                }, 1000);
            } else {
                const timestamp = utcTimeFromString(responseData[responseData.length - 1]?.logTime as string)
                dispatch(updateCheckRuningStatus({ functionId, status: runningStatus }));
                dispatch(updateCheckRuningEndTime({ functionId, timestamp }));
                await deleteFunctionLogs(sessionId)
            }
        } else {
            setTimeout(async () => {
                await dispatch(fetchAndStoreFunctionLogs(sessionId, functionId));
            }, 1000);
        }
    } catch (e) {
        console.error(e);
        dispatch(updateApiError(true));
    }
}

export const updateRuningStatus = (functionId: string, status: TestResultTypes) => async (dispatch: any) => {
    try {
        dispatch(updateCheckRuningStatus({ functionId, status }));
    } catch (e) {
        console.error(e);
        dispatch(updateApiError(true));
    }
}
