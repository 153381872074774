import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from "../../redux/store/hooks";
import { fetchPackagesList, updateSelectedPackage } from "../../redux/store/tsGuide";
import Main from '../STSteps';
import './Landing.scss';
import { LoadAllSubscriptions, LoadLatestVehicleData, loadCommunicatorData, Message, loadLatestDataFromCdc} from '@cd/sdds-common-components-react';
import { UniversalSearchActions, EquipmentOverview } from "@cd/sdds-common-components-react";
import { useParams } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom';
import { utcFormatDayFromString, utcFormatFromString } from '../../helpers/timeFormatter';

type LandingPageProps = {
  developerAccess: boolean;
  loggedInProfileInfo: ProfileInformationType
}

const LandingPage = ({ developerAccess, loggedInProfileInfo }: LandingPageProps) => {
  const { t } = useTranslation();
  const [packageList, setpackageList] = useState<string[] | undefined>([]);
  const [selectedPackageName, setSelectedPackageName] = useState<string>('');
  const [eqSearchStatus, setEqSearchStatus] = useState<boolean>(false);
  const dispatch = useAppDispatch()
  const { idParam } = useParams();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const tsGuide = useAppSelector<TsGuideState>(state => state.tsGuide)
  const { communicatorInfo, latestInfo, subscriptionInfo }: any = useAppSelector(state => state)
  const { selectedVehicle, isEquipmentApisLoading, selectedCustomer, selectedCommunicator, searchVehicleReference, saStatusLoading, saStatus, saStatusDate, csdStatsLoading, csdCount, csdDate, allCustomersForVehicle   } = useAppSelector<SearchState>(state => state.universalSearch)
  const isSimCardActivation = selectedPackageName.toLocaleLowerCase() === 'sim card activation';

  
  const eqParams = {
    'vin': selectedVehicle?.identification,
    'chassisnumber': selectedVehicle?.chassisNumber,
    'externalcustomerreference': selectedVehicle?.externalCustomerReference,
    'externalequipmentreference': selectedVehicle?.externalEquipmentReference,
    'externalstaffreference': tsGuide.selectedPersonnel?.externalStaffReference,
    'communicationdevice': selectedCommunicator?.rtcType || ''
  }

  useEffect(() => {
    dispatch(fetchPackagesList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let nameIdMap: Array<PkgNameIdMapType> = [];
    let packageNames = tsGuide.packages?.filter((pkg: PackageType)=> pkg.type === 'Equipment' 
    && pkg.name!=='SIM Card Activation' //this is temporary and should be removed once confirmation is received from MP
    && pkg.roles?.includes(loggedInProfileInfo.role)).map((pkg: PackageType) => {
      nameIdMap.push((({ name, id }) => ({ name, id }))(pkg));
      return pkg.name;
    })
    setpackageList(packageNames)

  }, [tsGuide.packages, loggedInProfileInfo.role]);

  useEffect(() => {
    if (!eqSearchStatus) return;
    else {
      setSelectedPackageName('');
    }
  }, [eqSearchStatus]);

  const selectPackageFromList = (packageName: string) => {
    const foundPackage = _.find(tsGuide.packages, { name: packageName })
    dispatch(updateSelectedPackage(foundPackage));
    setSelectedPackageName(foundPackage.name);
  }

  useEffect(() => {
    if (idParam && idParam !== searchVehicleReference) {
      dispatch(UniversalSearchActions.fetchEquipment(idParam))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idParam])

  useEffect(() => {
    if (selectedVehicle && selectedVehicle.externalEquipmentReference !== '00000000-0000-0000-0000-000000000000') {
        if (subscriptionInfo.equipmentVehicleReference !== selectedVehicle.externalEquipmentReference) {
          dispatch(LoadAllSubscriptions(selectedVehicle.externalEquipmentReference));
          dispatch(loadCommunicatorData(selectedVehicle.externalEquipmentReference));
          //load data for displaying configuration status & csd stats in equipment overview      
         dispatch(UniversalSearchActions.loadAggregateData(selectedVehicle.externalEquipmentReference));
         dispatch(UniversalSearchActions.loadCsdStats(selectedVehicle.externalEquipmentReference));
        }
        if(selectedPackageName)
        selectPackageFromList(selectedPackageName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVehicle, dispatch])

  useEffect(() => {
    /* Preserve vehicle selection by setting external equipment ref value in state
      1. when vehicle selection is done in current module but user navigates away to a sub section that doesnt need vehicle selection (loses it from url) and then navigates back -selectedVehicle
      2. when vehicle selection was done in another module and is part of url when landing on this module -idParam
    */
    // idParam is triggered first so the order is important, idParam is evaluated first in condition
    if (idParam || selectedVehicle) {
      navigate('/service-troubleshooting/'+ (idParam || selectedVehicle?.externalEquipmentReference), { state: { externalEquipmentReference: (idParam || selectedVehicle?.externalEquipmentReference) } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

   useEffect(() => {
     if (communicatorInfo?.EquipmentInformation && selectedVehicle){
       reloadLatestData(selectedVehicle.externalEquipmentReference);
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [communicatorInfo?.EquipmentInformation])

  const reloadLatestData = (externalEquipmentReference:string)=>{
    if(communicatorInfo.EquipmentInformation?.rtcType?.toLowerCase() === 'c400'){
      dispatch(loadLatestDataFromCdc(externalEquipmentReference))
    }else{
      dispatch(LoadLatestVehicleData(externalEquipmentReference))
    }
  }

  useEffect(() => {    
    if(!subscriptionInfo.equipmentVehicleReference) return;
    
     if(subscriptionInfo.equipementTotalSubscribedCustomers?.length === 0){      
      dispatch(UniversalSearchActions.saveAllCustomersForVehicle([]));
      return;
    }  
   
    //iterate through the active subscription packages to get other customers for the vehicle besides the owner
    let customersWithSubscriptions = [...allCustomersForVehicle]
     subscriptionInfo.equipementTotalSubscribedCustomers.forEach( (p:any) => {      
      var aCustomer: SimpleCustomerType = {
        externalCustomerReference: p.externalCustomerReference,
        name: p.customerName,
      }
      if (p.hasActiveSubscriptions && !allCustomersForVehicle.find((element) => element.externalCustomerReference === aCustomer.externalCustomerReference) ) {        
        customersWithSubscriptions.push(aCustomer);
      }
    }); 
    //update customerList in store 
    dispatch(UniversalSearchActions.saveAllCustomersForVehicle(customersWithSubscriptions));    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionInfo?.equipmentVehicleReference, subscriptionInfo?.equipementTotalSubscribedCustomers]);

  useEffect(() => {    
    if(!selectedCustomer) return;
    
    /*  - selectedCustomer by default is always the vehicle owner
        - check and add vehicle owner to allCustomers list (sometimes the vehicle owner doesnt have an active subscription on the vehicle) */
    if(!allCustomersForVehicle.find((element) => element.externalCustomerReference === selectedCustomer.externalCustomerReference)){
      let allSavedCustomers = [...allCustomersForVehicle]
      allSavedCustomers.unshift(selectedCustomer); // always add vehicle owner as the first option in allCustomers list      
      dispatch(UniversalSearchActions.saveAllCustomersForVehicle(allSavedCustomers))//update in store customerList
    }   
  }, [allCustomersForVehicle, dispatch, selectedCustomer]);

  return (
    <div className="landing-page">

      <EquipmentOverview searchStatus={eqSearchStatus} setSearchStatus={setEqSearchStatus}
      vehicle={selectedVehicle}
      customer={selectedCustomer}
      allCustomers={allCustomersForVehicle}
      subscriptionInfo={subscriptionInfo}
      reLoadAllSubscriptions={(externalEquipmentReference: string) => { dispatch(LoadAllSubscriptions(externalEquipmentReference)); } }
      communicatorInfo={communicatorInfo}
      reloadCommunicatorInfo={(externalEquipmentReference: string) => { dispatch(loadCommunicatorData(externalEquipmentReference)); } }
      latestInfo={latestInfo}
      isScaniaRead={selectedCustomer?.scaniaRead && selectedCustomer?.scaniaLegalDocumentationSigned}
      reLoadLatestVehicleData={reloadLatestData}
      isEquipmentApisLoading={isEquipmentApisLoading}
      equipmentRedirectPath="service-troubleshooting"
      loggedInProfileInfo={loggedInProfileInfo}
      saStatus={saStatus}
      saStatusDate={utcFormatFromString(saStatusDate)}
      isSaStatusLoading={saStatusLoading}
      csdCount={csdCount}
      csdDate={utcFormatDayFromString(csdDate)}
      isCsdStatsLoading={csdStatsLoading}      
      />

      <div className="st-view-wrapper">
        {selectedVehicle &&
          <sdds-inline-tabs-fullbleed>
            {((selectedCustomer?.scaniaLegalDocumentationSigned && selectedCustomer?.scaniaRead) || ((selectedCustomer === undefined || selectedCustomer === null) && (loggedInProfileInfo?.role === 'Admin' || loggedInProfileInfo?.role === 'GTS'))) && packageList && packageList.map((packageName, index) => {              
              return <div className={`sdds-inline-tabs-fullbleed--tab ${packageName === selectedPackageName ? "sdds-navigation-tabs--tab__active" : ""}`} onClick={() => { selectPackageFromList(packageName) }} onKeyDown={() => { selectPackageFromList(packageName) }} role="button" key={`pack0${index}`}>{t(packageName)}</div>
            })}
          </sdds-inline-tabs-fullbleed>
        }
        <div className="st-view-container">
          {/* To do - Also add condition to check if ST packages supported for selected vehicle/customer - info from api */}
          {((selectedCustomer?.scaniaLegalDocumentationSigned && selectedCustomer?.scaniaRead && selectedVehicle) || ((selectedCustomer === undefined || selectedCustomer === null) && (loggedInProfileInfo?.role === 'Admin' || loggedInProfileInfo?.role === 'GTS'))) && selectedPackageName === '' && <div className="selectPackageMessage sdds-body-01">{t("TS_core:SelectPackageToStartTroubleshooting")}</div>}

          {selectedPackageName !== '' &&
            <>
              <div className="section-scrollable">
                {isSimCardActivation && communicatorInfo.EquipmentInformation.gatewayReferenceId === '00000000-0000-0000-0000-000000000000' && 
                  <p>
                    <Message title={t('Non Migrated C200')} message={t("TS_core:NonMigratedC200Error")} type="error" minimal={false}/>
                  </p>
                }
                {!(isSimCardActivation && communicatorInfo.EquipmentInformation.gatewayReferenceId === '00000000-0000-0000-0000-000000000000') &&
                  <Main selectedPackage={tsGuide.selectedPackage as PackageType} eqParams={eqParams} selectedVehicle={selectedVehicle}/>
                }
              </div>
            </>
          }
        </div>
      </div>
    </div>)
}

export default LandingPage;