import React, { useEffect, useState } from 'react'
//import Stepper from '../../components/Steppers/Steppers';
import StepTabs from '../../components/StepTabs';
import FirstStep from './FirstStep';
import STTestStep from '../STTestStep';

import { useAppDispatch, useAppSelector } from '../../redux/store/hooks';
import { updateNextStep, fetchPersonalTest, selectingPersonnal, fetchFunctionsByPackage, reStartAllChecksExecution } from "../../redux/store/tsGuide";
import { RootState } from '../../redux/store';
import _ from 'lodash';

import "./FirstStep.scss";
import "./st-steps.scss"

type MainProps = {
    selectedPackage: PackageType,
    eqParams: any,
    selectedVehicle: any,
};

const Main = (props: MainProps) => {
    const { selectedPackage, eqParams, selectedVehicle } = props;
    const [staffSelected, setStaffSelected] = useState<boolean>(true)
    const dispatch = useAppDispatch()
    const requirePersonelSearch = selectedPackage?.name.toLowerCase() === 'remote heater control';
    const { universalSearch, tsGuide }: RootState = useAppSelector<RootState>(state => state)
    const areAnyTestsRunning: boolean = useAppSelector<boolean>(state => state.tsGuide.areAnyTestsRunning)

    useEffect(() => {
        dispatch(fetchFunctionsByPackage(selectedPackage?.id));
    }, [selectedPackage, selectedVehicle]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (tsGuide.functions && tsGuide.functions.length > 0) { }
        const functionNeedPersonnel = _.filter(tsGuide.functions, { name: 'CheckUserAccess' });
        if (functionNeedPersonnel && functionNeedPersonnel.length > 0) {
            dispatch(fetchPersonalTest(universalSearch.selectedVehicle?.externalCustomerReference as string))
        }
    }, [tsGuide.functions]);// eslint-disable-line react-hooks/exhaustive-deps

    const onPersonnelSelected = (personnel: any) => {
        setStaffSelected(true);
        dispatch(selectingPersonnal(personnel))
    }

    const warning = tsGuide.sessionsInfo.warning
    const failed = tsGuide.sessionsInfo.error

    const excelDownload = {
        vin: universalSearch.selectedVehicle?.identification as string,
        selectedPackage: selectedPackage,
        data: tsGuide.step2TestLists
    }

    const nextButtonClick = () => {
        if (requirePersonelSearch && !tsGuide.selectedPersonnel) {
            setStaffSelected(false);
        } else {
            dispatch(updateNextStep(tsGuide.step + 1));
        }
    }
    const reRunAllTests = () =>{
        dispatch(reStartAllChecksExecution(tsGuide.step,eqParams));
    }

    return (
        <div>
            {/* <Stepper step={tsGuide.step}/> */}
            <div className="sdds-u-mt3"></div>
            <StepTabs
                step={tsGuide.step}
                maxStep={3}
                next={nextButtonClick}
                prev={() => dispatch(updateNextStep(tsGuide.step - 1))}
                runAll={() => reRunAllTests()}
                warning={warning}
                failed={failed}
                excelDownload={excelDownload}
                areTestsRunnging = {areAnyTestsRunning}
            >
                {(() => {
                    switch (tsGuide.step) {
                        case 1:
                            return <FirstStep storeData={universalSearch}
                                requirePersonelSearch={requirePersonelSearch}
                                personnelSearch={tsGuide.personnelsList}
                                selectingPersonnel={onPersonnelSelected}
                                selectedPersonnel={tsGuide.selectedPersonnel}
                                hasError={requirePersonelSearch && !staffSelected}
                            />;
                        case 2:
                            return <STTestStep 
                                    packageId={selectedPackage.id} 
                                    eqParams={eqParams} 
                                    testStep={2} 
                                    testList={tsGuide.step2TestLists} 
                                    packageFunctionsList={tsGuide.functions}
                                    apiError = {tsGuide.apiError}
                                    apiNoData ={tsGuide.apiNoData}
                                    autoRun={true}
                                />;
                        case 3:
                            return <STTestStep 
                                    packageId={selectedPackage.id} 
                                    eqParams={eqParams}
                                    testStep={3} 
                                    testList={tsGuide.step3TestLists} 
                                    apiError = {tsGuide.apiError}
                                    apiNoData ={tsGuide.apiNoData}
                                    packageFunctionsList={tsGuide.functions}
                                    autoRun={false}
                                />;
                    }
                })()}

            </StepTabs>
        </div>
    )
}

export default Main;