import React, { useContext } from 'react'
import "./stepTabs.scss"
import { useTranslation } from 'react-i18next';
import ServiceTestsExcelExport from '../ServiceTestsExcelExport';
import RefreshIcon from "../../icons/RefreshIcon";
import { RBACWrapper } from 'react-simple-rbac';
import { UserContext } from '../../appContext';
import { useAppSelector } from '../../redux/store';

type StepTabsProps = {
    children: any,
    step: number,
    maxStep: number,
    prev: any,
    next: any,
    runAll: Function,
    warning: number,
    failed: number,
    excelDownload: ExcelExportProps
    areTestsRunnging: boolean
}
type ExcelExportProps = {
    data: any,
    vin: string,
    selectedPackage: PackageType
}
const StepTabs = (props: StepTabsProps) => {
    const { children, step, prev, next, maxStep, runAll, warning, failed, excelDownload, areTestsRunnging } = props
    const loggedInProfileInfo  = useContext<ProfileInformationType>(UserContext);
    const { selectedCustomer } = useAppSelector(state => state.universalSearch);
    const { t } = useTranslation();
    return (
        <> 
        {((selectedCustomer && selectedCustomer?.scaniaLegalDocumentationSigned && selectedCustomer?.scaniaRead) || ((selectedCustomer === undefined || selectedCustomer === null) && (loggedInProfileInfo?.role === 'Admin' || loggedInProfileInfo?.role === 'GTS'))) && 
            <div className="st-tabs-wrapper sdds-body-02">
                <div className={`header ${step === 2 ? 'step2' : ''}`}>
                    <div className='sdds-headline-05'> {(() => {
                        switch (step) {
                            case 1:
                                return t("TS_core:Step1") + ' - ' + t("TS_core:TSStep1Content");
                            case 2:
                                return t("TS_core:Step2") + ' - ' + t("TS_core:TSStep2Content");
                            case 3:
                                return t("TS_core:Step3") + ' - ' + t("TS_core:TSStep3Content");
                        }
                    })()}
                    </div>
                    <div className="info-wrapper">
                        {step === 2 &&
                            <>
                                <div className="info">
                                    <div className="warning"><span>{warning}</span> </div>
                                    <span>{t("TS_core:Warnings")}</span>
                                </div>
                                <div className="info">
                                    <div className="fail"><span>{failed}</span></div>
                                    <span>{t("TS_core:Failed")}</span>
                                </div>
                            </>
                        }
                    </div>
                    <div className="actions">
                        {step === 2 &&
                            <>
                                <div className="runtests">
                                    <button className="sdds-btn sdds-btn-secondary sdds-btn-sm" onClick={() => runAll()} disabled={areTestsRunnging}>
                                        <span>{t("TS_core:RunAllTests")}</span> <span className='sdds-btn-icon export-btn'><RefreshIcon /></span>
                                    </button>
                                </div>
                                <div className="export">
                                    <ServiceTestsExcelExport packageName={excelDownload.selectedPackage.name} data={excelDownload.data} vin={excelDownload.vin} disabled={areTestsRunnging}>
                                        {t("TS_core:ExportToExcel")}
                                    </ServiceTestsExcelExport>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className="content">
                    {children}
                </div>
            </div>
            }
            <div className="buttons">
            {((selectedCustomer && selectedCustomer?.scaniaLegalDocumentationSigned && selectedCustomer?.scaniaRead) || ((selectedCustomer === undefined || selectedCustomer === null) && (loggedInProfileInfo?.role === 'Admin' || loggedInProfileInfo?.role === 'GTS'))) && !(step === 1 || step > maxStep) && <button className={`sdds-btn sdds-btn-secondary sdds-btn-md ${step === 1 || step > maxStep ? 'disabled' : ''}`} disabled={step === 1 || step > maxStep} onClick={prev}>{t("TS_core:PreviousStep")}</button>}
                {step === 1 && <button className="sdds-btn sdds-btn-primary sdds-btn-md" onClick={next}>{t("TS_core:NextStep")}</button>}
                {step === 2 && excelDownload.selectedPackage.name !== 'SIM Card Activation' &&
                    <RBACWrapper oneOf requiredRoles={['Admin', 'GTS']}>
                        <button className={`sdds-btn sdds-btn-primary sdds-btn-md ${step > (maxStep - 1) ? 'disabled' : ''}`} disabled={step > (maxStep - 1)} onClick={next}>{t("TS_core:NextStep")}</button>
                    </RBACWrapper>
                }
                {step === 2 && excelDownload.selectedPackage.name === 'SIM Card Activation' &&
                    <RBACWrapper oneOf requiredRoles={['Admin', 'GTS', 'LoTH', 'FQ', 'Assistance']}>
                        <button className={`sdds-btn sdds-btn-primary sdds-btn-md ${step > (maxStep - 1) ? 'disabled' : ''}`} disabled={step > (maxStep - 1)} onClick={next}>{t("TS_core:NextStep")}</button>
                    </RBACWrapper>
                }
            </div>
        </>
    )
}
export default StepTabs